
//export const baseUrl = "http://192.168.1.40:8000/v1";
//export const imgUrl = "http://192.168.1.40:8000/";

export const baseUrl = "https://apiuat.dbiexchange.com/v1";
export const imgUrl = "https://apiuat.dbiexchange.com";
export const adminbaseUrl = "https://apiuat.dbiexchange.com/";
export const socketUrl = "https://streamuat.dbiexchange.com/";

// export const baseUrl = "http://192.168.1.104:8000/v1";
// export const imgUrl = "http://192.168.1.104";
// export const adminbaseUrl = "http://192.168.1.104/";
// export const socketUrl = "https://streamuat.dbiexchange.com/";

// export const imgUrl = "https://api.dbiexchange.com";
// export const baseUrl = "https://api.dbiexchange.com/v1";
// export const adminbaseUrl = "https://api.dbiexchange.com/";
// export const socketUrl = "wss://stream.dbiexchange.com/";

let lang = (localStorage.getItem("lang")) ? localStorage.getItem("lang") : 'en';

//console.log("lANG ",lang);

export const aheader = {
    headers: {
        devicetype: 1,
        lang: lang,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};

//console.log("aheader ",aheader);

export const aheader_form = {
    headers: {
        devicetype: 1,
        "content-type": "multipart/form-data",
        lang: lang,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};



